import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #161616;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
  margin-top: 16px;
  transition: background-color 0.2s;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: ${shade(0.8, '#161616')};
  }

  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
`;
