import { FaWhatsapp } from 'react-icons/fa';
import { FiMail, FiPhoneOutgoing } from 'react-icons/fi';

import Form from '../../components/Form';
import Logo from '../../components/Logo';
import Card from '../../components/Card';
import Slideshow from '../../components/Slideshow';

import imgLocation from '../../assets/terrenos.png';
import imgLand from '../../assets/gr-terrenos.png';
import imgChange from '../../assets/permuta.png';

import logoAjImg from '../../assets/logo-aj.png';
import ametrinaImg from '../../assets/ametrina-branco.png';

import video02 from '../../assets/video.mp4';

import {
  Container,
  HeaderContainer,
  BuildingQualitiesContainer,
  WrapperBuildingQualities,
  BuildingQualitiesContent,
  IconsBuildingQualities,
  TitleBuildingQualities,
  VideoContainer,
  VideoTitle,
  VideoContent,
  Video,
  SlideShowContainer,
  MapContainer,
  ContactContainer,
  Map,
  WrapperContactContainer,
  TextContactContainer,
  ButtonSixth,
  FormSixth,
  Footer,
  FormAndPrice,
  LinkWhatsapp,
  WhatsappIcon,
} from './styles';

function Home() {
  return (
    <Container>
      <HeaderContainer>
        <img src={ametrinaImg} alt="Anderson Juliano Corretor" />
      </HeaderContainer>

      <VideoContainer>
        <VideoTitle>
          <h1>
            Confira o melhor de <strong>Itapema</strong>
          </h1>
        </VideoTitle>
        <VideoContent>
          <Video>
            <video autoPlay muted loop controls>
              <source src={video02} type="video/mp4" />
            </video>
          </Video>
        </VideoContent>
      </VideoContainer>

      <SlideShowContainer>
        <Slideshow />
      </SlideShowContainer>

      <BuildingQualitiesContainer>
        <WrapperBuildingQualities>
          <TitleBuildingQualities>
            <h2>Tudo pensando em você</h2>
          </TitleBuildingQualities>

          <BuildingQualitiesContent>
            <IconsBuildingQualities>
              <Card
                image={imgChange}
                title="Lazer para sua Família"
                description="Uma ampla área indoor e outdoor com opções de lazer incríveis para todos os estilos e idades."
              />
              <Card
                image={imgLocation}
                title="Ótima Localização"
                description="Empreendimento muito bem localizado próximos das mais belas praias de Santa Catarina."
              />
              <Card
                image={imgLand}
                title="Luxo e Sofisticação"
                description="Espaços planejados e com o que há de mais incrível e sofisticado em acabamento"
              />
            </IconsBuildingQualities>
          </BuildingQualitiesContent>
        </WrapperBuildingQualities>
      </BuildingQualitiesContainer>

      <FormAndPrice>
        <Logo />
        <Form />
      </FormAndPrice>

      <MapContainer>
        <VideoTitle>
          <h1>Converse conosco</h1>
        </VideoTitle>
        <Map>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2111.255002124251!2d-48.602522495253936!3d-27.134461436448728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8afcc776dc6c3%3A0x8d0be8146f02d3d3!2sAnderson%20Juliano%20Corretor%20de%20Im%C3%B3veis!5e0!3m2!1spt-BR!2sbr!4v1669936919947!5m2!1spt-BR!2sbr"
            frameBorder="0"
            aria-hidden="false"
            title="Mapa Imobiliária Anderson Juliano"
          />
        </Map>
      </MapContainer>

      <ContactContainer>
        <WrapperContactContainer>
          <FormSixth>
            <Form withoutTextarea />
          </FormSixth>
          <TextContactContainer>
            <h1>Deseja um atendimento personalizado</h1>

            <ButtonSixth>
              <a
                className="botao-fone fadeScroll1"
                href={`tel:5547${process.env.REACT_APP_PHONE_NUMBER}`}
              >
                <FiPhoneOutgoing />
                Ligar
              </a>
              <a
                className="botoes fadeScroll2"
                href={`https://api.whatsapp.com/send?phone=5547${process.env.REACT_APP_PHONE_NUMBER}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp />
                Whatsapp
              </a>
              <a
                className="botoes fadeScroll3"
                href={`mailto: ${process.env.REACT_APP_EMAIL}`}
              >
                <FiMail />
                E-mail
              </a>
            </ButtonSixth>
          </TextContactContainer>
        </WrapperContactContainer>
      </ContactContainer>

      <Footer>
        <img src={logoAjImg} alt="Anderson Juliano Corretor" />
        <LinkWhatsapp
          href={`https://api.whatsapp.com/send?phone=5547${process.env.REACT_APP_PHONE_NUMBER}`}
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappIcon className="fa fa-whatsapp" />
        </LinkWhatsapp>
      </Footer>
    </Container>
  );
}

export default Home;
