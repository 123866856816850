import imgZero from '../../assets/0.jpg';
import imgOne from '../../assets/1.jpg';
import imgTwo from '../../assets/2.jpg';
import imgThree from '../../assets/3.jpg';
import imgFour from '../../assets/4.jpg';
import imgFive from '../../assets/5.jpg';
import imgSix from '../../assets/6.jpg';
import imgSeven from '../../assets/7.jpg';
import imgEight from '../../assets/8.jpg';
import imgNine from '../../assets/9.jpg';
import imgTen from '../../assets/10.jpg';
import imgEleven from '../../assets/11.jpg';
import imgTwelve from '../../assets/12.jpg';
import imgThirteen from '../../assets/13.jpg';
import imgFourteen from '../../assets/14.jpg';
import imgFifteen from '../../assets/15.jpg';
import imgSixteen from '../../assets/16.jpg';
import imgSeventeen from '../../assets/17.jpg';
import imgEighteen from '../../assets/18.jpg';
import imgNineteen from '../../assets/19.jpg';
import imgTwentyOne from '../../assets/21.jpg';
import imgTwentyTwo from '../../assets/22.jpg';
import imgTwentyThree from '../../assets/23.jpg';
import imgTwentyFive from '../../assets/25.jpg';

export const images = [
  { original: imgZero },
  { original: imgOne },
  { original: imgTwo },
  { original: imgThree },
  { original: imgFour },
  { original: imgFive },
  { original: imgSix },
  { original: imgSeven },
  { original: imgEight },
  { original: imgNine },
  { original: imgTen },
  { original: imgEleven },
  { original: imgTwelve },
  { original: imgThirteen },
  { original: imgFourteen },
  { original: imgFifteen },
  { original: imgSixteen },
  { original: imgSeventeen },
  { original: imgEighteen },
  { original: imgNineteen },
  { original: imgTwentyOne },
  { original: imgTwentyTwo },
  { original: imgTwentyThree },
  { original: imgTwentyFive },
];
