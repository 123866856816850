import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  align-items: center;
  width: 100%;
  flex: 1;
  width: 500px;

  @media screen and (max-width: 1000px) {
    width: 90vw;
    max-width: 495px;
    margin: -20px auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-family: 'Noto Sans Limbu', sans-serif;
    font-weight: 700;
    color: white;
    font-size: 35px;
    line-height: 40px;
    text-align: left;
  }
`;

export const SloganContact = styled.div`
  background: rgb(11, 28, 69);
  background: linear-gradient(
    90deg,
    rgba(11, 28, 69, 1) 0%,
    rgba(7, 45, 133, 1) 48%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-top: 16px;
  text-align: left;
  padding: 16px;

  h1 {
    font-family: 'Noto Sans Limbu', sans-serif;
    font-weight: 700;
    color: white;
    font-size: 35px;
    line-height: 40px;
  }

  @media screen and (max-width: 1000px) {
    h1 {
      font-size: 25px;
      line-height: 30px;
    }
  }
`;
