import { Container, Content, Icone } from './styles';

type PropType = {
  image: string;
  title: string;
  description: string;
};

function Card({ image, description, title }: PropType) {
  return (
    <Container>
      <Content>
        <Icone>
          <img src={image} alt="test" />
        </Icone>
      </Content>
      <h1>{title}</h1>
      <h2>{description}</h2>
    </Container>
  );
}

export default Card;
