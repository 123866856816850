import ImageGallery from 'react-image-gallery';
import { images } from './configImg';

import './styles.css';

function Slideshow() {
  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showPlayButton={false}
      slideDuration={1000}
      slideInterval={5000}
      showBullets
      autoPlay
    />
  );
}

export default Slideshow;
