import { Container, SloganContact, Content } from './styles';

function Logo() {
  return (
    <Container>
      <Content>
        <h1>Últimas unidades</h1>
        <SloganContact>
          <h1>Entrada</h1>
          <h1>R$ 70.000,00</h1>
        </SloganContact>

        <SloganContact>
          <h1>100x</h1>
          <h1>R$ 2.500,00</h1>
        </SloganContact>

        <SloganContact>
          <h1>+ reforços</h1>
        </SloganContact>

        <SloganContact>
          <h1>02 suítes</h1>
          <h1>1298m&sup2; de área de lazer</h1>
        </SloganContact>
      </Content>
    </Container>
  );
}

export default Logo;
