import { ButtonHTMLAttributes } from 'react';
import { FiArrowRight } from 'react-icons/fi';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};
function Button({ children, loading, ...rest }: ButtonProps) {
  return (
    <Container type="button" {...rest}>
      {loading ? 'Carregando...' : children}
      <FiArrowRight />
    </Container>
  );
}

export default Button;
