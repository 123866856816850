import { useCallback, useRef } from 'react';
import { FiUser, FiMail, FiPhoneOutgoing } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form as FormWeb } from '@unform/web';
import emailJs from 'emailjs-com';

import { toast } from 'react-hot-toast';
import Input from './Input';

import { Container, ContactContent } from './styles';
import Textarea from './Textarea';
import Button from './Button';

type DataType = {
  name: string;
  email: string;
  phone: string;
  description: string;
};

type FormProps = {
  withoutTextarea?: boolean;
};

function Form({ withoutTextarea = false }: FormProps) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: DataType) => {
    try {
      const templateEmail = {
        name: data.name,
        phone: data.phone,
        email: data.email,
        message: data.description,
        product: 'Ametrina Residence',
      };

      await emailJs.send(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        templateEmail,
        `${process.env.REACT_APP_USER_ID}`,
      );

      toast.success('Seus dados foram enviados, e logo entraremos em contato.');
      formRef.current?.reset();
    } catch {
      toast.error('Algo de errado aconteceu.');
    }
  }, []);

  return (
    <Container withoutTextarea={withoutTextarea}>
      <ContactContent>
        {!withoutTextarea ? (
          <>
            <h1>Bem vindo à sua</h1>
            <h2>Vida nova!</h2>
          </>
        ) : (
          <h3>Entre em contato</h3>
        )}

        <FormWeb onSubmit={handleSubmit} ref={formRef}>
          <Input name="name" icon={FiUser} placeholder="Nome" />
          <Input
            name="phone"
            icon={FiPhoneOutgoing}
            placeholder="Telefone"
            type="tel"
          />
          <Input name="email" icon={FiMail} placeholder="E-mail" />

          {!withoutTextarea && (
            <Textarea
              name="description"
              placeholder="Nos diga o que você procura"
            />
          )}
          <Button type="submit">Entrar em contato</Button>
        </FormWeb>
      </ContactContent>
    </Container>
  );
}

export default Form;
