import styled from 'styled-components';
import { shade } from 'polished';
import bgImg from '../../assets/1.jpg';
import bgImg2 from '../../assets/15.jpg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: lightgrey;
  width: 100%;
  height: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.75);

  img {
    margin: 100px auto;
    width: 40%;
  }
  @media screen and (max-width: 1000px) {
    img {
      margin: 50px auto;
      width: 40vw;
      max-width: 250px;
    }
  }
`;

export const BuildingQualitiesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;
`;

export const WrapperBuildingQualities = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const BuildingQualitiesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 0px;
  }
`;

export const IconsBuildingQualities = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TitleBuildingQualities = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    font-family: 'Playfair Display';
    text-transform: capitalize;
    font-size: 40px;
    color: #0b1c45;
    margin-top: 30px;
    margin-bottom: 70px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  @media screen and (max-width: 1000px) {
    h2 {
      font-size: 20px;
      margin-bottom: 35px;
      margin-top: 20px;
    }
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  background-color: #0b1c45;
`;

export const VideoTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;

  h1 {
    color: #fff;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    margin-right: 15px;
  }

  strong {
    font-weight: bold;
    font-family: 'Noto Sans Limbu', sans-serif;
    color: #bc9e5c;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 10px;
    padding-bottom: 5px;
    h1 {
      font-size: 15px;
    }
  }
`;

export const VideoContent = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

export const Video = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;

  video {
    width: 80vw;
  }

  @media screen and (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

export const SlideShowContainer = styled.div`
  display: flex;
  flex: 1;
  height: 1000px;
  width: 100%;
`;

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  background-color: #0b1c45;
`;

export const Map = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 70px;

  iframe {
    width: 50vw;
    height: 50vh;
  }

  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
    iframe {
      width: auto;
      height: auto;
    }
  }
`;

export const FormAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  width: 100%;
  background-image: url(${bgImg2});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.75);
  padding: 40px 10% 0;

  @media screen and (max-width: 1000px) {
    margin-top: 20px auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 470px 530px;
    justify-content: center;
    grid-template-areas:
      'imagem'
      'form';
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;
  background-image: url(${bgImg2});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`;

export const WrapperContactContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
`;

export const TextContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 9%;

  h1 {
    color: white;
    -webkit-text-stroke: 2px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
    font-size: 40px;
    font-weight: bold;
    width: 70%;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      width: 100vw;
      font-size: 20px;
      margin-bottom: 20px;
      color: white;
      font-family: 'Roboto';
      font-weight: normal;
      text-shadow: none;
    }
  }
`;

export const ButtonSixth = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    background-color: #0b1c45;
    padding: 10px 20px;
    margin: 0 7px;
    border-radius: 10px;
    font-size: 14px;
    align-items: center;
    display: flex;
    font-weight: bold;

    svg {
      margin-right: 8px;
    }

    :hover {
      background: ${shade(0.2, '#0b1c45')};
      font-weight: bold;
    }
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-top: 10px;

    a {
      margin-bottom: 8px;
    }
  }
`;

export const FormSixth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #0b1c45;
  color: white;
  text-align: center;

  img {
    max-width: 350px;
    padding: 15px 0;
  }

  @media screen and (max-width: 1000px) {
    img {
      max-width: 150px;
    }
  }
`;

export const LinkWhatsapp = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
`;

export const WhatsappIcon = styled.i`
  margin-top: 16px;
`;
