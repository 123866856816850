import 'react-image-gallery/styles/css/image-gallery.css';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  img {
   max-width: 100%;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
  
  h2 {
    font-size: 1em;
  }
`;
