import styled from 'styled-components';

type ContainerProps = {
  withoutTextarea: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  margin: 90px 0 0 50px;
  display: inline-block;
  grid-area: form;

  :after {
    content: '';
    position: absolute;
    width: 85%;
    height: 85%;
    border: 10px solid lightgrey;
    left: ${({ withoutTextarea }) => (!withoutTextarea ? '98px' : '-40px')};
    top: -40px;
    z-index: 1;
    animation-name: transition;
    animation-duration: 2s;
  }

  @media screen and (max-width: 1000px) {
    margin: 0px auto;

    :after {
      display: none;
    }
  }
`;

export const ContactContent = styled.div`
  position: relative;
  background-color: #0b1c45;
  width: 495px;
  padding: 20px 40px 40px 40px;
  margin-bottom: 70px;
  z-index: 2;

  h1 {
    color: #fff;
    font-size: 25px;
    font-family: 'Noto Sans Limbu', sans-serif;
    line-height: 1.1em;
    font-weight: bold;
  }

  h2 {
    margin-top: -10px;
    color: #fff;
    font-size: 16px;
    font-family: 'Noto Sans Limbu', sans-serif;
    font-size: 50px;
    font-weight: bold;
  }

  h3 {
    color: #fff;
    font-size: 30px;
    font-family: 'Noto Sans Limbu', sans-serif;
    line-height: 1.1em;
    margin-bottom: 10px;
    font-weight: bold;
  }

  @media screen and (max-width: 1000px) {
    width: 90vw;
    max-width: 495px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 15px;
      font-family: 'Noto Sans Limbu', sans-serif;
      line-height: 1.1em;
      font-weight: bold;
    }

    h2 {
      margin-top: -10px;
      color: #fff;
      font-size: 16px;
      font-family: 'Noto Sans Limbu', sans-serif;
      font-size: 40px;
      font-weight: bold;
    }
  }
`;
