import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat';
  color: #0b1c45;

  h1 {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }

  h2 {
    width: 280px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  @media screen and (max-width: 1000px) {
    margin-bottom: 50px;

    h1 {
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 16px;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: inline-block;

  ::after {
    border: 4px solid;
    border-image: linear-gradient(90deg, #9e7a47, #d7bf6f, #9e7a47);
    border-image-slice: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: -15px;
    top: -15px;
    z-index: 1;
  }
`;

export const Icone = styled.div`
  position: relative;
  background-color: #0b1c45;
  width: 70px;
  height: 70px;
  z-index: 2;
`;
